import styled, { css } from 'styled-components';
import { getThemeColor, ThemeColor } from '@happn/theme';
const Separator = styled.div `
  margin: 1.5rem 0;
  height: 0.1rem;
  width: 100%;
`;
const lightVariantStyles = css `
  ${Separator} {
    background-color: ${getThemeColor(ThemeColor.BorderLightSubdued)};
  }
`;
const whiteVariantStyles = css `
  ${Separator} {
    background-color: ${getThemeColor(ThemeColor.BorderWhite)};
  }
`;
const CardMultiSection = styled.section `
  border: 0.1rem solid ${getThemeColor(ThemeColor.BorderDarkMuted)};
  border-radius: 2rem;
  padding: 2rem;

  ${({ variant }) => {
    return variant === 'light' ? lightVariantStyles : whiteVariantStyles;
}}
`;
export default { CardMultiSection, Separator };
