import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Styled from './CardMultiSection.styles';
const CardMultiSection = ({ children, withSeparator, variant = 'light', }) => {
    const isNodeList = Array.isArray(children);
    if (isNodeList) {
        const childrenList = children;
        return (_jsx(Styled.CardMultiSection, Object.assign({ variant: variant }, { children: childrenList === null || childrenList === void 0 ? void 0 : childrenList.map((child, i) => {
                const isLastChild = i === (childrenList === null || childrenList === void 0 ? void 0 : childrenList.length) - 1;
                const shouldDisplaySeparator = withSeparator && !isLastChild;
                return (_jsxs("div", { children: [child, shouldDisplaySeparator && _jsx(Styled.Separator, {}, void 0)] }, i));
            }) }), void 0));
    }
    return (_jsx(Styled.CardMultiSection, Object.assign({ variant: variant }, { children: children }), void 0));
};
export default CardMultiSection;
