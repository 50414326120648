import styled, { css } from 'styled-components';
import { getThemeFont, getThemeColor, ThemeColor, ThemeFont, media, } from '@happn/theme';
export const clickable = css `
  cursor: pointer;
`;
// Colors
const primaryColor = css `
  color: ${getThemeColor(ThemeColor.PrimaryDark)};
`;
const secondaryColor = css `
  color: ${getThemeColor(ThemeColor.White)};
`;
const tertiaryColor = css `
  color: ${getThemeColor(ThemeColor.DarkSubdued)};
`;
// Fonts
const lanordRegularFont = css `
  font-family: ${getThemeFont(ThemeFont.LaNordRegular)};
`;
const lanordMediumFont = css `
  font-family: ${getThemeFont(ThemeFont.LaNordMedium)};
`;
const lanordBoldFont = css `
  font-family: ${getThemeFont(ThemeFont.LaNordBold)};
`;
// Hero
const heroBlack = css `
  font-family: ${getThemeFont(ThemeFont.SpykBlack)};
  font-size: 3.25rem;
  line-height: 3.625rem;

  ${media.minSmall} {
    font-size: 3.75rem;
    line-height: 4.125rem;
  }
`;
const heroBlackItalic = css `
  ${heroBlack}
  font-family: ${getThemeFont(ThemeFont.SpykBlackItalic)}
`;
// Display
const display = css `
  font-size: 2rem;
  line-height: 2.25rem;

  ${media.minSmall} {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
`;
const displayRegular = css `
  ${display}
  ${lanordRegularFont}
`;
const displayMedium = css `
  ${display}
  ${lanordMediumFont}
`;
const displayBold = css `
  ${display}
  ${lanordBoldFont}
`;
// Title
const title = css `
  font-size: 2rem;
  line-height: 2.375rem;
`;
const titleRegular = css `
  ${title}
  ${lanordRegularFont}
`;
const titleMedium = css `
  ${title}
  ${lanordMediumFont}
`;
const titleBold = css `
  ${title}
  ${lanordBoldFont}
`;
// Headline 1
const headline1 = css `
  font-size: 1rem;
  line-height: 1.25rem;

  ${media.minSmall} {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;
const headline1Regular = css `
  ${headline1}
  ${lanordRegularFont}
`;
const headline1Medium = css `
  ${headline1}
  ${lanordMediumFont}
`;
const headline1Bold = css `
  ${headline1}
  ${lanordBoldFont}
`;
// Headline 2
const headline2 = css `
  font-size: 0.9375rem;
  line-height: 1.25rem;

  ${media.minSmall} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;
const headline2Regular = css `
  ${headline2}
  ${lanordRegularFont}
`;
const headline2Medium = css `
  ${headline2}
  ${lanordMediumFont}
`;
const headline2Bold = css `
  ${headline1}
  ${lanordBoldFont}
`;
// Body
const body = css `
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
const bodyRegular = css `
  ${body}
  ${lanordRegularFont};
`;
const bodyMedium = css `
  ${body}
  ${lanordMediumFont};
`;
const bodyBold = css `
  ${body}
  ${lanordBoldFont};
`;
// Caption 1
const caption1 = css `
  font-size: 0.8125rem;
  line-height: 1rem;
`;
const caption1Regular = css `
  ${caption1}
  ${lanordRegularFont};
`;
const caption1Medium = css `
  ${caption1}
  ${lanordMediumFont};
`;
const caption1Bold = css `
  ${caption1}
  ${lanordBoldFont};
`;
// Caption 2
const caption2 = css `
  font-size: 0.75rem;
  line-height: 1rem;
`;
const caption2Regular = css `
  ${caption2}
  ${lanordRegularFont};
`;
const caption2Medium = css `
  ${caption2}
  ${lanordMediumFont};
`;
const caption2Bold = css `
  ${caption2}
  ${lanordBoldFont};
`;
const Text = styled.p `
  letter-spacing: 0;
  text-transform: ${({ textTransform }) => textTransform || 'none'};

  ${({ color }) => {
    switch (color) {
        case 'secondary':
            return secondaryColor;
        case 'tertiary':
            return tertiaryColor;
        default:
        case 'primary':
            return primaryColor;
    }
}}

  ${({ variant }) => {
    switch (variant) {
        case 'heroBlack':
            return heroBlack;
        case 'heroBlackItalic':
            return heroBlackItalic;
        case 'displayRegular':
            return displayRegular;
        case 'displayMedium':
            return displayMedium;
        case 'displayBold':
            return displayBold;
        case 'titleRegular':
            return titleRegular;
        case 'titleMedium':
            return titleMedium;
        case 'titleBold':
            return titleBold;
        case 'headline1Regular':
            return headline1Regular;
        case 'headline1Medium':
            return headline1Medium;
        case 'headline1Bold':
            return headline1Bold;
        case 'headline2Regular':
            return headline2Regular;
        case 'headline2Medium':
            return headline2Medium;
        case 'headline2Bold':
            return headline2Bold;
        case 'bodyRegular':
            return bodyRegular;
        case 'bodyMedium':
            return bodyMedium;
        case 'bodyBold':
            return bodyBold;
        case 'caption1Regular':
            return caption1Regular;
        case 'caption1Medium':
            return caption1Medium;
        case 'caption1Bold':
            return caption1Bold;
        case 'caption2Regular':
            return caption2Regular;
        case 'caption2Medium':
            return caption2Medium;
        case 'caption2Bold':
            return caption2Bold;
        default:
            return bodyRegular;
    }
}}

  ${({ onClick }) => onClick && clickable};
`;
export default { Text };
