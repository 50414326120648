import { nonUndefined } from '@happn/helpers';
import { useTraitsList } from '@happn/traits';
import { formatHeight, } from '../../apollo';
const useTraitsWithAnswers = (traits) => {
    const traitList = useTraitsList();
    if (!(traits === null || traits === void 0 ? void 0 : traits.length)) {
        return [];
    }
    const getMatchingTraitAnswer = (traitsList, trait) => {
        var _a, _b, _c;
        switch (trait.type) {
            case 'FLOAT_RANGE': {
                return formatHeight(trait.answer);
            }
            case 'SINGLE_CHOICE': {
                const traitById = traitsList.find((t) => t.id === trait.id);
                const matchingAnswer = (_b = (_a = traitById === null || traitById === void 0 ? void 0 : traitById.options) === null || _a === void 0 ? void 0 : _a.singleChoice) === null || _b === void 0 ? void 0 : _b.find((option) => option.id === trait.answer);
                return (_c = matchingAnswer === null || matchingAnswer === void 0 ? void 0 : matchingAnswer.translatedText) !== null && _c !== void 0 ? _c : '';
            }
            default: {
                return '';
            }
        }
    };
    const getMatchingTraitMultiAnswers = (traitsList, traitV2) => {
        var _a, _b, _c, _d, _e, _f;
        if (traitV2.type === 'MULTI_CHOICE') {
            const traitById = (_b = (_a = traitsList.find((t) => t.id === traitV2.id)) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.multiChoices;
            return (_c = traitV2 === null || traitV2 === void 0 ? void 0 : traitV2.answers) === null || _c === void 0 ? void 0 : _c.map((trait) => {
                var _a, _b;
                return {
                    optionId: trait.optionId,
                    categoryId: trait.categoryId,
                    defaultText: (_a = traitById === null || traitById === void 0 ? void 0 : traitById.find((t) => t.id === trait.optionId)) === null || _a === void 0 ? void 0 : _a.translatedText,
                    categoryText: (_b = traitById === null || traitById === void 0 ? void 0 : traitById.find((t) => t.categoryId === trait.categoryId)) === null || _b === void 0 ? void 0 : _b.defaultText,
                };
            });
        }
        if (traitV2.type === 'USER_INPUT') {
            const traitById = (_e = (_d = traitsList.find((t) => t.id === traitV2.id)) === null || _d === void 0 ? void 0 : _d.options) === null || _e === void 0 ? void 0 : _e.userInput;
            return (_f = traitV2 === null || traitV2 === void 0 ? void 0 : traitV2.answers) === null || _f === void 0 ? void 0 : _f.map((trait) => {
                var _a, _b;
                return {
                    optionId: trait.optionId,
                    categoryId: trait.categoryId,
                    defaultText: (_a = traitById === null || traitById === void 0 ? void 0 : traitById.find((t) => t.id === trait.optionId)) === null || _a === void 0 ? void 0 : _a.defaultText,
                    categoryText: (_b = traitById === null || traitById === void 0 ? void 0 : traitById.find((t) => t.categoryId === trait.categoryId)) === null || _b === void 0 ? void 0 : _b.defaultText,
                    answerText: trait.answerText,
                };
            });
        }
        return [];
    };
    return traits === null || traits === void 0 ? void 0 : traits.map((trait) => {
        var _a;
        const matchingTrait = traitList.find((singleTrait) => singleTrait.id === trait.id);
        if (!matchingTrait) {
            return undefined;
        }
        return Object.assign({ answerId: trait.type === 'FLOAT_RANGE' ? '' : trait.answer, answer: getMatchingTraitAnswer(traitList, trait), rawAnswer: trait.rawAnswer, answers: (_a = getMatchingTraitMultiAnswers(traitList, trait)) !== null && _a !== void 0 ? _a : [] }, matchingTrait);
    }).filter(nonUndefined);
};
export default useTraitsWithAnswers;
