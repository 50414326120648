import styled, { css } from 'styled-components';
import { getThemeColor, ThemeColor } from '@happn/theme';
import Text from '../Text';
const Description = styled(Text).attrs({ variant: 'caption1Regular' }) `
  color: ${getThemeColor(ThemeColor.TextDarkSubdued)};
`;
const Title = styled(Text).attrs({ variant: 'bodyMedium' }) `
  color: ${getThemeColor(ThemeColor.TextDark)};
`;
const CardSelect = styled.div `
  padding: 1rem;
  min-height: 4.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${getThemeColor(ThemeColor.FillWhite)};
  border-radius: 1rem;
  transition: background-color 0.2s ease-in;
  box-shadow: 0px 8px 12px 0px #100f0a0f;

  ${({ selected }) => selected &&
    css `
      background-color: ${getThemeColor(ThemeColor.FillDark)};

      ${Title}, ${Description} {
        color: ${getThemeColor(ThemeColor.TextLight)};
      }
    `}
`;
export default { CardSelect, Description, Title };
