import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { MinusIcon, TickIcon } from '@happn/icons';
import theme from '@happn/theme';
import Styled from './Checkbox.styles';
const Checkbox = ({ disabled, checked = false, partial = false, className, testId = 'checkbox', onClick, }) => {
    const checkBoxColor = checked ? theme.colors.dark : theme.colors.white;
    const handleClick = useCallback((e) => {
        e.preventDefault();
        !disabled && (onClick === null || onClick === void 0 ? void 0 : onClick(!checked, e));
    }, [checked, disabled, onClick]);
    return (_jsxs(Styled.Outer, Object.assign({ onClick: handleClick, isDisabled: disabled, className: className, "data-testid": testId, "data-disabled": disabled }, { children: [_jsx(Styled.Input, { type: "checkbox", readOnly: true, checked: checked, disabled: disabled }, void 0), _jsx(Styled.Checkbox, { children: partial ? (_jsx(MinusIcon, { color: checkBoxColor }, void 0)) : (_jsx(TickIcon, { color: checkBoxColor }, void 0)) }, void 0)] }), void 0));
};
export default Checkbox;
