import styled, { css } from 'styled-components';
import { getThemeColor, ThemeColor } from '@happn/theme';
const Checkbox = styled.div `
  background-color: ${getThemeColor(ThemeColor.White)};
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  border: 0.125rem solid ${getThemeColor(ThemeColor.Quinary)};
  border-radius: 0.25rem;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
const Input = styled.input `
  display: none;

  &:checked + ${Checkbox} {
    background: ${getThemeColor(ThemeColor.IconSuccess)};
    border-color: ${getThemeColor(ThemeColor.IconSuccess)};
  }
`;
const BaseOuter = styled.div `
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &:not([data-disabled='true']):hover > ${Input}:not(:checked) + ${Checkbox} {
    background: ${getThemeColor(ThemeColor.Quinary)};
    border-color: ${getThemeColor(ThemeColor.Quinary)};
  }
`;
const disabledCss = css `
  cursor: not-allowed;

  * {
    pointer-events: none;
  }
`;
const Outer = styled(BaseOuter) `
  ${({ isDisabled }) => isDisabled && disabledCss}
`;
export default {
    Outer,
    Checkbox,
    Input,
};
