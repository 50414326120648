var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import update from 'immutability-helper';
import { useCallback } from 'react';
import loggerService from '@happn/logger';
import { USER_ID_ME, USER_QUERY, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
import { USER_SAVE_TRAIT_ANSWER_MUTATION, USER_TRAITS_QUERY, USER_REMOVE_TRAIT_ANSWER_MUTATION, } from './gql';
import useTraitsWithAnswers from './useTraitsWithAnswers';
const useTraits = (id = USER_ID_ME) => {
    const client = useApolloClient();
    const { data } = useQuery(USER_TRAITS_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    const userId = data === null || data === void 0 ? void 0 : data.user.id;
    const traitsWithAnswers = useTraitsWithAnswers(data === null || data === void 0 ? void 0 : data.user.traitsV2);
    const saveAnswer = useCallback((traitId, input) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield client.mutate({
                mutation: USER_SAVE_TRAIT_ANSWER_MUTATION,
                refetchQueries: [USER_QUERY],
                variables: {
                    userId: userId,
                    traitId,
                    input,
                },
                update(cache, { data }) {
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            traits(current) {
                                const traitIndex = current.findIndex((t) => t.id === traitId);
                                if (!(data === null || data === void 0 ? void 0 : data.userSaveTraitAnswer) || traitIndex === -1) {
                                    return current;
                                }
                                return update(current, {
                                    [traitIndex]: {
                                        answer: {
                                            $set: data.userSaveTraitAnswer.answer,
                                        },
                                        rawAnswer: {
                                            $set: data.userSaveTraitAnswer.rawAnswer,
                                        },
                                    },
                                });
                            },
                        },
                    });
                },
            });
            return true;
        }
        catch (err) {
            loggerService.error(new Error(`Could not save trait answer, ${err}`));
            return false;
        }
    }), [client, userId]);
    const removeAnswer = useCallback((traitId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const response = yield client.mutate({
                mutation: USER_REMOVE_TRAIT_ANSWER_MUTATION,
                refetchQueries: [USER_QUERY],
                variables: { userId: userId, traitId },
                update(cache, { data }) {
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            traits(current) {
                                const traitIndex = current.findIndex((t) => t.id === traitId);
                                if (!(data === null || data === void 0 ? void 0 : data.userRemoveTraitAnswer.success) ||
                                    traitIndex === -1) {
                                    return current;
                                }
                                return update(current, {
                                    [traitIndex]: {
                                        answer: { $set: '' },
                                        rawAnswer: { $set: null },
                                    },
                                });
                            },
                        },
                    });
                },
            });
            return ((_a = response.data) === null || _a === void 0 ? void 0 : _a.userRemoveTraitAnswer.success) || false;
        }
        catch (err) {
            loggerService.error(new Error(`Could not remove trait answer, ${err}`));
            return false;
        }
    }), [client, userId]);
    return {
        traits: (data === null || data === void 0 ? void 0 : data.user.traits) || [],
        traitsV2: traitsWithAnswers,
        saveAnswer,
        removeAnswer,
    };
};
export default useTraits;
