import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import CheckBox from '../Checkbox';
import Styled from './CardSelect.styles';
const CardSelect = ({ title, description, onClick, isChecked = false, className, }) => {
    const handleKeyDown = useCallback((event) => {
        event.preventDefault();
        if (event.key === 'Enter' || event.key === ' ') {
            onClick();
        }
    }, [onClick]);
    return (_jsxs(Styled.CardSelect, Object.assign({ role: "button", tabIndex: 0, selected: isChecked, onKeyDown: handleKeyDown, className: className, onClick: onClick }, { children: [_jsxs("div", { children: [_jsx(Styled.Title, { children: title }, void 0), !!description && (_jsx(Styled.Description, { children: description }, void 0))] }, void 0), _jsx(CheckBox, { checked: isChecked }, void 0)] }), void 0));
};
export default CardSelect;
